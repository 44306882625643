import React, { FC, useState } from 'react';
import styled from 'styled-components/macro';
import Column from './Column';
import Input from './Input';
import BlockButton from './BlockButton';
import Row from './Row';
import FormBox from './FormBox';
import ShiftMaster, {
  ShiftMasterDraft,
  shiftMasterFromDraft,
} from '../models/ShiftMaster';
import noop from '../utils/noop';
import singleDecimal from '../utils/singleDecimal';
import { hideByDisplayNone } from '../utils/hideable';

export interface GeneralShiftEntriesFormProps {
  value: ShiftMasterDraft;
  readOnly: boolean;
  onSubmit?: (value: ShiftMaster) => any;
  onCancel?: () => any;
  isElmers: boolean;
}

const GeneralShiftEntriesForm: React.FC<
  GeneralShiftEntriesFormProps
> = props => {
  const [value, setValue] = useState(props.value);
  const { onSubmit = noop, onCancel, readOnly, isElmers, ...rest } = props;

  function handleSubmit() {
    const shiftMaster = shiftMasterFromDraft(value);
    if (isElmers) {
      const { beginningPokerBank, columnTotal, cashDrop } = shiftMaster;
      if (
        (beginningPokerBank === null || beginningPokerBank <= 0) &&
        !window.confirm('Did you mean to enter that value for Disp. Total?')
      ) {
        return;
      }
      if (
        (columnTotal === null || columnTotal <= 0) &&
        !window.confirm('Did you mean to enter that value for Column Balance?')
      ) {
        return;
      }
      if (
        (cashDrop === null || cashDrop <= 0) &&
        !window.confirm(
          'Did you mean to enter that value for Cash Drop (Drop Total + Cash)?',
        )
      ) {
        return;
      }
    }
    onSubmit(shiftMaster);
  }

  return (
    <FormBox
      {...rest}
      title="Remaining Entries : Form"
      onCloseClick={handleSubmit}
    >
      <FormBody>
        <h1>General Shift Entries</h1>
        {!isElmers && (
          <>
            <FormRow>
              <FormRowEntry>
                <label>Cigarette Discount Overrings:</label>
                <Input
                  isMoney
                  readOnly={readOnly}
                  value={value.cigaretteDiscountOverring || 0}
                  onValueChange={cigaretteDiscountOverring =>
                    setValue({ ...value, cigaretteDiscountOverring })
                  }
                  data-testid="cigDiscountOverringsInput"
                />
              </FormRowEntry>
              <FormRowEntry>
                <label>Cigarette Overrings:</label>
                <Input
                  isMoney
                  readOnly={readOnly}
                  value={value.cigaretteOverrings || 0}
                  onValueChange={cigaretteOverrings =>
                    setValue({ ...value, cigaretteOverrings })
                  }
                  data-testid="cigOverringsInput"
                />
              </FormRowEntry>
              <FormRowEntry>
                <label>Cigarette O/R (units):</label>
                <Input
                  readOnly={readOnly}
                  value={value.cigaretteOr || ''}
                  onValueChange={cigaretteOr =>
                    setValue({
                      ...value,
                      cigaretteOr: singleDecimal(cigaretteOr),
                    })
                  }
                  step="0.1"
                  data-testid="cigOverringUnitsInput"
                />
              </FormRowEntry>
            </FormRow>
            <FormRow>
              <FormRowEntry>
                <label>Debit Fee:</label>
                <Input
                  isMoney
                  readOnly={readOnly}
                  value={value.promotion || '0'}
                  onValueChange={promotion => setValue({ ...value, promotion })}
                  data-testid="debitFeeInput"
                />
              </FormRowEntry>
              <FormRowEntry>
                <label>Lottery Coupons:</label>
                <Input
                  isMoney
                  readOnly={readOnly}
                  value={value.lotteryCoupons || 0}
                  onValueChange={lotteryCoupons =>
                    setValue({ ...value, lotteryCoupons })
                  }
                  data-testid="lotteryCouponsInput"
                />
              </FormRowEntry>
              <FormRowEntry>
                <label>Employee Meals:</label>
                <Input
                  isMoney
                  readOnly={readOnly}
                  value={value.employeeMeals || 0}
                  onValueChange={employeeMeals =>
                    setValue({ ...value, employeeMeals })
                  }
                  data-testid="employeeMealsInput"
                />
              </FormRowEntry>
            </FormRow>
          </>
        )}
        <FormRow>
          {!isElmers && (
            <FormRowEntry>
              <label>Overrings:</label>
              <Input
                isMoney
                readOnly={readOnly}
                value={value.overrings || 0}
                onValueChange={overrings => setValue({ ...value, overrings })}
                data-testid="overringsInput"
              />
            </FormRowEntry>
          )}

          <FormRowEntry>
            <label>Other Cash In:</label>
            <Input
              isMoney
              readOnly={readOnly}
              value={value.other || 0}
              onValueChange={other => setValue({ ...value, other })}
              data-testid="otherCashInInput"
            />
          </FormRowEntry>
          {isElmers && (
            <GrowFormRowEntry>
              <label>Other Cash in Comments</label>
              <WvComment
                readOnly={readOnly}
                value={value.othercomments || ''}
                onValueChange={othercomments =>
                  setValue({ ...value, othercomments })
                }
                data-testid="otherCashInCommentsInput"
              />
            </GrowFormRowEntry>
          )}
        </FormRow>
        <FormRow>
          {!isElmers && (
            <FormRowEntry>
              <label>Cash Drop:</label>
              <Input
                isMoney
                readOnly={readOnly}
                value={value.cashDrop || 0}
                onValueChange={cashDrop => setValue({ ...value, cashDrop })}
                data-testid="cashDropInput"
              />
            </FormRowEntry>
          )}
          <FormRowEntry>
            <label>VLM Cash In:</label>
            <Input
              isMoney
              readOnly={readOnly}
              value={value.vlmCashIn || 0}
              onValueChange={vlmCashIn => setValue({ ...value, vlmCashIn })}
              data-testid="vlmCashInInput"
            />
          </FormRowEntry>
        </FormRow>
        {!isElmers && (
          <FormRow>
            <FormRowEntry>
              <label>Payout:</label>
              <Input
                isMoney
                readOnly={readOnly}
                value={value.payouts || 0}
                onValueChange={payouts => setValue({ ...value, payouts })}
                data-testid="payoutInput"
              />
            </FormRowEntry>
            <GrowFormRowEntry>
              <CommentLabel>Payout Comments:</CommentLabel>
              <WvComment
                readOnly={readOnly}
                value={value.payoutsComments || ''}
                onValueChange={payoutsComments =>
                  setValue({ ...value, payoutsComments })
                }
                data-testid="payoutCommentsInput"
              />
            </GrowFormRowEntry>
          </FormRow>
        )}
        {!isElmers && (
          <FormRow>
            <FormRowEntry>
              <label>Jam Bills Out:</label>
              <Input
                isMoney
                readOnly={readOnly}
                value={value.jamBillsOut || 0}
                onValueChange={jamBillsOut =>
                  setValue({ ...value, jamBillsOut })
                }
                data-testid="jamBillsOutInput"
              />
            </FormRowEntry>
            <GrowFormRowEntry>
              <label>Other Cash in Comments</label>
              <Comment
                readOnly={readOnly}
                value={value.othercomments || ''}
                onValueChange={othercomments =>
                  setValue({ ...value, othercomments })
                }
                data-testid="otherCashInCommentsInput"
              />
            </GrowFormRowEntry>
          </FormRow>
        )}
        <FormRow>
          <FormRowEntry hide={isElmers}>
            <label>Guest Rewards Payout:</label>
            <Input
              isMoney
              readOnly={readOnly}
              value={value.guestRewardPayout}
              onValueChange={guestRewardPayout =>
                setValue({ ...value, guestRewardPayout })
              }
              data-testid="guestRewardsPayoutInput"
            />
          </FormRowEntry>
          <GrowFormRowEntry hide={isElmers}>
            <LongLabel>Guest Reward Payout Comments</LongLabel>
            <GuestRewardsComment
              readOnly={readOnly}
              value={value.guestRewardPayoutComments || ''}
              onValueChange={guestRewardPayoutComments =>
                setValue({ ...value, guestRewardPayoutComments })
              }
              data-testid="guestRewardsPayoutCommentsInput"
            />
          </GrowFormRowEntry>
        </FormRow>
        <FormRow hide={isElmers}>
          <FormRowEntry>
            <label>Change Out:</label>
            <Input
              isMoney
              readOnly={readOnly}
              value={value.changeOut || 0}
              onValueChange={changeOut => setValue({ ...value, changeOut })}
              data-testid="changeOutInput"
            />
          </FormRowEntry>
          <FormRowEntry>
            <label>Change In:</label>
            <Input
              isMoney
              readOnly={readOnly}
              value={value.changeIn || 0}
              onValueChange={changeIn => setValue({ ...value, changeIn })}
              data-testid="changeInInput"
            />
          </FormRowEntry>
        </FormRow>
        <FormRow>
          <ShortFormRowEntry hide={isElmers}>
            <AtmLongLabel>Credit Card Slips</AtmLongLabel>
            <Input
              isMoney
              readOnly={readOnly}
              value={value.creditCardSlips || 0}
              onValueChange={creditCardSlips =>
                setValue({ ...value, creditCardSlips })
              }
              data-testid="creditCardSlipsInput"
            />
          </ShortFormRowEntry>
          <ShortFormRowEntry hide={isElmers}>
            <AtmLongLabel>Credit Card Count</AtmLongLabel>
            <Input
              readOnly={readOnly}
              value={value.creditCardSlipCount || 0}
              onValueChange={creditCardSlipCount =>
                setValue({ ...value, creditCardSlipCount })
              }
              data-testid="creditCardCountInput"
            />
          </ShortFormRowEntry>
        </FormRow>
        <TillEntries>
          <TillEntryRow>
            <FormRowEntry>
              <label>Beginning Till</label>
              <Input
                isMoney
                readOnly={readOnly}
                value={value.beginningTill || 0}
                onValueChange={beginningTill =>
                  setValue({ ...value, beginningTill })
                }
                data-testid="beginningTillInput"
              />
            </FormRowEntry>
            <FormRowEntry hide={isElmers}>
              <label>Beginning Change</label>
              <Input
                isMoney
                readOnly={readOnly}
                value={value.beginningChange || 0}
                onValueChange={beginningChange =>
                  setValue({ ...value, beginningChange })
                }
                data-testid="beginningChangeInput"
              />
            </FormRowEntry>
          </TillEntryRow>

          <TillEntryRow>
            <FormRowEntry>
              <label>Ending Till</label>
              <Input
                isMoney
                readOnly={readOnly}
                value={value.endingTill || 0}
                onValueChange={endingTill => setValue({ ...value, endingTill })}
                data-testid="endingTillInput"
              />
            </FormRowEntry>
            <FormRowEntry hide={isElmers}>
              <label>Ending Change</label>
              <Input
                isMoney
                readOnly={readOnly}
                value={value.endingChange || 0}
                onValueChange={endingChange =>
                  setValue({ ...value, endingChange })
                }
                data-testid="endingChangeInput"
              />
            </FormRowEntry>
          </TillEntryRow>
        </TillEntries>
        <SafeBox>
          <h2>Safe {isElmers ? 'Reports' : ''}</h2>
          {isElmers ? (
            <ElmersSafeReportForm
              readOnly={readOnly}
              value={value}
              onChange={setValue}
            />
          ) : (
            <StandardSafeReportForm
              readOnly={readOnly}
              value={value}
              onChange={setValue}
            />
          )}
          <FormRow>
            <FormRowEntry>
              <label>Tube Discrepency (Over)</label>
              <SafeMoneyInput
                readOnly={readOnly}
                value={value.discrepencyOver}
                onValueChange={discrepencyOver =>
                  setValue({ ...value, discrepencyOver })
                }
                style={{
                  backgroundColor:
                    value.discrepencyOver && +value.discrepencyOver !== 0
                      ? 'rgb(255, 229, 0)'
                      : 'white',
                }}
                data-testid="tubeDiscrepencyOverInput"
              />
            </FormRowEntry>

            <GrowFormRowEntry>
              <LongLabel>Tube Discrepency Comments:</LongLabel>
              <DiscrepencyComment
                readOnly={readOnly}
                value={value.discrepencyComments || ''}
                onValueChange={discrepencyComments =>
                  setValue({ ...value, discrepencyComments })
                }
                data-testid="tubeDiscrepencyCommentsInput"
              />
            </GrowFormRowEntry>
          </FormRow>
          <FormRow>
            <FormRowEntry>
              <label>Tube Discrepency (Short)</label>
              <SafeMoneyInput
                readOnly={readOnly}
                value={value.discrepencyShort}
                onValueChange={discrepencyShort =>
                  setValue({ ...value, discrepencyShort })
                }
                style={{
                  backgroundColor:
                    value.discrepencyShort && +value.discrepencyShort !== 0
                      ? 'rgb(255, 229, 0)'
                      : 'white',
                }}
                data-testid="tubeDiscrepencyShortInput"
              />
            </FormRowEntry>
          </FormRow>
        </SafeBox>
        <Line />
        <ButtonRow>
          <BlockButton onClick={handleSubmit} data-testid="enterButton">
            Enter
          </BlockButton>
          <BlockButton onClick={onCancel} data-testid="cancelButton">
            CANCEL
          </BlockButton>
        </ButtonRow>
      </FormBody>
    </FormBox>
  );
};

export default styled(GeneralShiftEntriesForm)`
  h1 {
    text-align: center;
    margin-bottom: 10px;
  }
  padding: 30px 20px 20px 20px;
  width: 1100px;
`;

const FormBody = styled(Column)`
  background-color: #eeeeee;
`;

const FormRow = styled(Row)<{ hide?: boolean }>`
  align-items: center;

  & + & {
    margin-top: 10px;
  }

  ${Input}, ${Input} {
    height: 25px;
    width: 120px;
    padding: 0;
    text-align: right;
  }
  ${hideByDisplayNone}
`;

const FormRowEntry = styled(Row)<{ hide?: boolean }>`
  align-items: center;
  justify-content: flex-start;

  label {
    width: 200px;
  }

  * + & {
    margin-left: 20px;
  }
  ${hideByDisplayNone}
`;

const GrowFormRowEntry = styled(FormRowEntry)`
  flex: 1;

  ${Input} {
    flex: 1;
    max-width: 270px;
  }
`;

const ShortFormRowEntry = styled(FormRowEntry)`
  label {
    width: 82px;
  }
`;

const AtmLongLabel = styled.label`
  && {
    width: 190px;
    margin-right: 10px;
  }
`;

const SafeBox = styled(Column)`
  margin-top: 2px;
  width: 900px;
  margin-top: 2px;
  padding: 10px 5px 10px 5px;
  border: 1px solid #333;

  h2 {
    text-align: center;
    text-decoration: underline;
  }

  label {
    width: 172px;
  }
`;

const ButtonRow = styled(Row)`
  justify-content: space-evenly;
  padding: 0 20px 20px 20px;
  width: 700px;
  margin-right: 100px;

  ${BlockButton} {
    height: 30px;
    min-height: 30px;
    padding: 0;
    width: 100px;
    min-width: 100px;
  }
`;

const TillEntryRow = styled(FormRow)`
  justify-content: center;
`;

const TillEntries = styled(Column)`
  margin-top: 100px;
  margin-top: 100px;
`;

const LongLabel = styled.label`
  && {
    width: 220px;
  }
`;

const SafeMoneyInput = styled(Input)`
  && {
    width: 120px;
  }
`;

const TotalSafeMoneyInput = styled(Input)`
  && {
    width: 130px;
  }
`;

const DepositTotalLabel = styled.label`
  && {
    width: 137px;
    margin-left: 72px;
  }
`;

const DiscrepencyComment = styled(Input)`
  && {
    height: 25px;
    padding-left: 0;
    max-width: 350px;
  }
`;

const Comment = styled(Input)`
  height: 30px;
  padding-left: 0;
`;

const WvComment = styled(Comment)`
  && {
    max-width: 456px;
  }
`;

const GuestRewardsComment = styled(Comment)`
  && {
    max-width: 380px;
  }
`;

const CommentLabel = styled.label`
  && {
    width: 205px;
  }
`;

const Line = styled.hr`
  position: relative;
  margin: 0;
  margin-top: 12px;
  left: -3px;
  right: unset;
  width: 850px;
  border: 3px solid black;
`;

const ColTotalLabel = styled.label`
  && {
    width: 80px;
  }
`;

const EntryColumn = styled(Column)`
  height: 130px;
  flex: 1 0 auto;
  padding-top: 10px;
  h3 {
    margin-left: 20px;
  }
`;

const ElmersSafeReportFormRow = styled(FormRow)`
  border-bottom: 1px solid black;
  margin-bottom: 10px;
`;

interface SafeReportFormProps {
  readOnly?: boolean;
  value: ShiftMasterDraft;
  onChange(value: ShiftMasterDraft): void;
}

const ElmersSafeReportForm: FC<SafeReportFormProps> = ({
  readOnly,
  value,
  onChange,
}) => {
  return (
    <ElmersSafeReportFormRow>
      <EntryColumn>
        <h3>From Shift Report</h3>
        <FormRowEntry>
          <LongLabel>Disp. Total</LongLabel>
          <SafeMoneyInput
            readOnly={readOnly}
            value={value.beginningPokerBank || 0}
            onValueChange={beginningPokerBank =>
              onChange({ ...value, beginningPokerBank })
            }
            data-testid="cashDispensedTotalInput"
          />
        </FormRowEntry>
        <FormRowEntry>
          <LongLabel>Cash Drop (Drop Total + Cash)</LongLabel>
          <Input
            isMoney
            readOnly={readOnly}
            value={value.cashDrop || 0}
            onValueChange={cashDrop => onChange({ ...value, cashDrop })}
            data-testid="cashDropInput"
          />
        </FormRowEntry>
        <FormRowEntry>
          <LongLabel>Column Balance</LongLabel>
          <SafeMoneyInput
            readOnly={readOnly}
            value={value.columnTotal || 0}
            onValueChange={columnTotal => onChange({ ...value, columnTotal })}
            data-testid="colTotalInput"
          />
        </FormRowEntry>
      </EntryColumn>
      <EntryColumn>
        <h3>From Status Report</h3>
        <FormRowEntry>
          <LongLabel>Deposit Total</LongLabel>
          <SafeMoneyInput
            readOnly={readOnly}
            value={value.cassetteDeposit || 0}
            onValueChange={cassetteDeposit =>
              onChange({ ...value, cassetteDeposit })
            }
            data-testid="depositTotalInput"
          />
        </FormRowEntry>
      </EntryColumn>
    </ElmersSafeReportFormRow>
  );
};

const StandardSafeReportForm: FC<SafeReportFormProps> = ({
  readOnly,
  value,
  onChange,
}) => {
  return (
    <FormRow>
      <FormRowEntry>
        <label>Cash Dispensed Total:</label>
        <SafeMoneyInput
          readOnly={readOnly}
          value={value.beginningPokerBank || 0}
          onValueChange={beginningPokerBank =>
            onChange({ ...value, beginningPokerBank })
          }
          data-testid="cashDispensedTotalInput"
        />
      </FormRowEntry>

      <FormRowEntry>
        <ColTotalLabel>Col Total (column)</ColTotalLabel>
        <TotalSafeMoneyInput
          readOnly={readOnly}
          value={value.columnTotal || 0}
          onValueChange={columnTotal => onChange({ ...value, columnTotal })}
          data-testid="colTotalInput"
        />
      </FormRowEntry>
      <FormRowEntry>
        <DepositTotalLabel>Deposit Total</DepositTotalLabel>
        <TotalSafeMoneyInput
          readOnly={readOnly}
          value={value.cassetteDeposit || 0}
          onValueChange={cassetteDeposit =>
            onChange({ ...value, cassetteDeposit })
          }
          data-testid="depositTotalInput"
        />
      </FormRowEntry>
    </FormRow>
  );
};
